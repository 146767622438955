import "./style.css";

import Swiper from "swiper/swiper-bundle";
import "swiper/swiper-bundle.min.css";

const dayMs = 24 * 60 * 60 * 1000;

function getCurrentSlideId(timestamps) {
  const now = Date.now() - dayMs;
  for (const [i, time] of timestamps.entries()) {
    if (time > now) return i;
  }
  return timestamps.length - 1;
}

Array.from(document.getElementsByClassName("timeline-slider")).forEach(
  (wrapper) => {
    wrapper.setAttribute("data-active", "true");
    const [prevEl, nextEl] = wrapper.querySelectorAll(
      ".timeline-slider__nav button"
    );

    const baseConfig = {
      slidesPerView: 2,
      spaceBetween: 24,
      grid: { rows: 1 },
    };

    const swiper = new Swiper(wrapper.querySelector(".swiper"), {
      navigation: { prevEl, nextEl },
      grabCursor: true,
      spaceBetween: 16,
      slidesPerView: 1,
      breakpoints: {
        640: baseConfig,
        1024: {
          ...baseConfig,
          slidesPerView: 3,
        },
      },
    });

    const disableButton = (btn) => {
      btn.setAttribute("disabled", "true");
    };

    swiper.on("activeIndexChange", () => {
      if (swiper.isBeginning) disableButton(prevEl);
      if (swiper.isEnd) disableButton(nextEl);
    });

    const currentId = getCurrentSlideId(
      Array.from(wrapper.querySelectorAll(".card")).map((el) =>
        new Date(el.getAttribute("data-date")).getTime()
      )
    );

    swiper.slideTo(currentId, 0);
  }
);
