import "./style.css";

if (!("ontouchstart" in window))
  Array.from(document.getElementsByClassName("location-map")).forEach(
    (wrapper) => {
      const links = Array.from(wrapper.querySelectorAll(".map-dot a"));

      const highlight = (highlighted) => {
        links.forEach((link) => {
          link.setAttribute(
            "data-hidden",
            !!highlighted && link !== highlighted
          );
        });
      };

      links.forEach((link) => {
        link.addEventListener("mouseover", () => highlight(link));
        link.addEventListener("mouseout", () => highlight());
      });
    }
  );
