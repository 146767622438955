import { takeOverAudio } from "../..";

Array.from(document.querySelectorAll(".card-video")).forEach((wrapper) => {
  const video = wrapper.querySelector("video");
  const button = wrapper.querySelector("button.speaker-button");

  if (!video || !button) return;

  const updateButtonMuteState = () =>
    button.dispatchEvent(
      new CustomEvent("mutestatechange", { detail: { value: video.muted } })
    );

  button.addEventListener("click", () => {
    if (video.muted) {
      video.muted = false;
      takeOverAudio(() => {
        video.muted = true;
        updateButtonMuteState();
      });
    } else {
      video.muted = true;
      takeOverAudio(null);
    }
    updateButtonMuteState();
  });
});
