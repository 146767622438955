import "./style.css";

Array.from(document.getElementsByClassName("site-nav")).forEach((wrapper) => {
  let visible = false;
  const btn = wrapper.querySelector("button");
  btn.addEventListener("click", () => {
    visible = !visible;
    wrapper.setAttribute("data-visible", visible);
    btn.innerText = visible ? "Zurück" : "Menü";
  });
});
