import animScrollTo from "animated-scroll-to";

function scrollTo(elem) {
  const { top, height } = elem.getBoundingClientRect();
  animScrollTo(top + window.scrollY + height / 2 - window.innerHeight / 2);
}

const scrollLinks = Array.from(
  document.getElementsByTagName("a")
).filter((link) => link.href.includes("#"));

if (scrollLinks.length) {
  const entries = scrollLinks
    .map((link) => ({
      link,
      el: document.getElementById(link.href.split("#")[1]),
    }))
    .filter(({ el }) => !!el);

  entries.forEach(({ link, el }) =>
    link.addEventListener("click", () => scrollTo(el))
  );
}

if (window.location.hash) {
  const target = document.querySelector(window.location.hash);
  if (target) scrollTo(target);
}
