import { takeOverAudio } from "..";

Array.from(document.querySelectorAll(".card-audio")).forEach((wrapper) => {
  const audio = wrapper.querySelector("audio");
  const button = wrapper.querySelector("button.speaker-button");

  if (!audio || !button) return;

  const updateButtonMuteState = () =>
    button.dispatchEvent(
      new CustomEvent("mutestatechange", { detail: { value: audio.paused } })
    );

  button.addEventListener("click", () => {
    if (audio.paused) {
      takeOverAudio(() => {
        audio.pause();
        updateButtonMuteState();
      });
      audio.play();
    } else {
      takeOverAudio(null);
      audio.pause();
    }

    updateButtonMuteState();
  });
});
